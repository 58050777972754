<template>
    <header class="header bg-white d-flex position-fixed container-fluid p-0">
        <Nav />
        <div class="d-inline-flex flex-wrap w-100 px-3">
            <div class="d-flex align-items-center w-100">
                <div class="d-flex flex-column mw-80 me-auto">
                    <span v-show="this.is_user_name" class="vw-60 text-nowrap-mask mb-1 me-0">
						{{ this.user.last_name }} {{ this.user.first_name }} {{ this.user.middle_name }}
						<sup v-show="this.is_beta_tester" class="text-danger">beta</sup>
                    </span>
                    <div class="d-flex">
                        <span v-if="this.user.services.egrn_count" class="text-white fs-5 d-flex fw-normal bg-warning me-2 p-1 px-2 rounded-1">
                            <span class="fw-semi font-semi" v-text="this.user.services.egrn_count + ' вып.'" />
                        </span>

                        <span v-if="this.user.services.balance" class="text-white fs-5 d-flex fw-normal bg-raspberry me-2 p-1 px-2 rounded-1">
                            <span class="fw-semi font-semi" v-text="this.user.services.balance + ' р.'" />
                        </span>

                        <span v-if="this.view_is_landscape && this.user.services.antiznak_count" class="text-white fs-5 d-flex fw-normal bg-info me-2 p-1 px-2 rounded-1">
                            <span class="fw-semi font-semi" v-text="this.user.services.antiznak_count + ' фото'" />
                        </span>

                        <span v-if="this.view_is_landscape && this.user.services.use_advert_budget" class="text-white fs-5 d-flex fw-normal bg-pink me-2 p-1 px-2 rounded-1">
                            <span class="fw-semi font-semi" v-text="this.user.services.advert_budget.balance + ' р.'" />
                        </span>
                    </div>
                </div>
                <button v-if="this.user.services.chat"
						type="button"
						class="btn d-flex align-items-center justify-content-center outline-0 me-2 d-none d-sm-block" style="padding: 2px !important;">
                    <img :src="require('@/assets/icons/chat.svg')" alt="Чат" width="28" height="28">
                </button>
				<button type="button"
						class="btn d-flex flex-column justify-content-center outline-0 mw-20 ms-auto me-0 rounded-circle border-1 border-brd-primary bg-white"
						:data-bs-toggle="(this.user_show_details) ? 'offcanvas' : false"
						:data-bs-target="(this.user_show_details) ? '#userSettings' : false"
						:aria-controls="(this.user_show_details) ? 'userSettings' : false" style="padding: 3px !important;">
					<UserPic :userId="this.user.id"
							 :agencyId="this.user.agency_id"
							 :userLogo="this.user.avatar"
							 :userName="this.user.name"
							 :showIsOnline="false"
							 :logoSize="42"
							 className="d-block rounded-circle" />
				</button>
			</div>
        </div>
		<UserProfile v-if="this.user_show_details" :user="this.user" />
    </header>
</template>

<script>
	import api from '../../api';
    import Nav from './Nav';
	import CommonService from "../../services/CommonService";
	import Session from "@/services/Session";
	import UserProfile from "@/components/common/UserProfile";
	import UserPic from "@/components/common/UserPic";

    export default {
        name: "MainHeader",

		data() {
			return {
				user: {
					id: null,
					first_name: null,
					middle_name: null,
					last_name: null,
					agency_id: null,
					avatar: null,
					region_id: null,
					services: {
						tarif: {
							days: 0,
							expired: true
						},
						egrn_count: null,
						balance: null,
						chat: false,
						antiznak_count: false,
						use_advert_budget: false,
						advert_budget: {},
					}
				},
				users_online: [],
			}
		},
        components: {
			UserPic,
			UserProfile,
            Nav
        },
		methods: {
			getUserInfo() {
				return api.get('/user/info', {
					params: {
						meta: this.$store.commit.browserInfo
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getUserInfo()::axios', response.data);

					if (response.status == 200) {

            if (!this.isEmpty(response.data.user.permissions)) {
              const permissions = response.data.user.permissions;
              const routeName = this.$route.name;

              if (routeName == 'search' && permissions.menu_search == 0) {
                if (permissions.menu_objects == 0 && permissions.menu_clients == 0 && permissions.menu_submissions == 0) {
                  this.$router.push('/calendar');
                } else if (permissions.menu_objects == 0 && permissions.menu_clients == 0) {
                  this.$router.push('/requisitions');
                } else if (permissions.menu_objects == 0) {
                  this.$router.push('/clients');
                } else {
                  this.$router.push('/objects');
                }
              }

              if (routeName == 'objects' && permissions.menu_objects == 0) {
                if (permissions.menu_clients == 0 && permissions.menu_submissions == 0) {
                  this.$router.push('/calendar');
                } else if (permissions.menu_clients == 0) {
                  this.$router.push('/requisitions');
                } else {
                  this.$router.push('/clients');
                }
              }

              if (routeName == 'clients' && permissions.menu_clients == 0) {
                if (permissions.menu_search == 0 && permissions.menu_objects == 0 && permissions.menu_submissions == 0) {
                  this.$router.push('/calendar');
                } else if (permissions.menu_search == 0 && permissions.menu_objects == 0) {
                  this.$router.push('/requisitions');
                } else if (permissions.menu_search == 0) {
                  this.$router.push('/objects');
                } else {
                  this.$router.push('/search');
                }
              }

              if (routeName == 'requisitions' && permissions.menu_submissions == 0) {
                if (permissions.menu_search == 0 && permissions.menu_objects == 0 && permissions.menu_clients == 0) {
                  this.$router.push('/calendar');
                } else if (permissions.menu_search == 0 && permissions.menu_objects == 0) {
                  this.$router.push('/clients');
                } else if (permissions.menu_search == 0) {
                  this.$router.push('/objects');
                } else {
                  this.$router.push('/search');
                }
              }
            }

						if (response.data.success && response.data.user_id !== null) {
							let user = response.data.user;
							if (typeof this.$store !== "undefined") {
								this.$store.commit('setUserInfo', user);
								if (response.data.remember_me) {
									this.$store.commit('addAccount', {
										id: response.data.user.id,
										name: response.data.user.last_name + ' ' + response.data.user.first_name + ' ' + response.data.user.middle_name,
										phone: response.data.user.phone,
										avatar: response.data.user.avatar,
										session_id: response.data.session_id,
										timestamp: CommonService.getTimestamp()
									});
								}
							}
							return user;
						} else {
							this.$router.push('/logout');
						}
					}

					return {};
				}).catch(function (error) {

					CommonService.log('error', 'getUserInfo()::axios', error);

					this.$router.push('/logout');
				});
			},
			getAdvertsBudget() {
				return api.get('/adverts/budget', {
					is_agency: this.is_agency
				})
				.then((response) => {

					CommonService.log('debug', 'getAdvertsBudget()::axios', response.data);

					if (response.status == 200 && response.data.success) {
						return response.data.budget;
					}

					return {};
				}).catch(function (error) {
					CommonService.log('error', 'getAdvertsBudget()::axios', error);
				});
			},
			setUserOnline() {

				if (!this.user_is_auth)
					return [];

				return api.put('/user/online').then((response) => {

					CommonService.log('debug', 'setUserOnline()::axios', response.data);

					if (response.status == 200 && response.data.success) {

						if (typeof this.$store !== "undefined")
							this.$store.commit('setUserOnline', response.data.online);

						this.users_online = response.data.online;

						return response.data.online;
					}

					return [];
				}).catch(function (error) {

					CommonService.log('error', 'setUserOnline()::axios', error);

					this.$store.commit('setUserOnline', []);
					return [];
				});
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			}
		},
		mounted() {

			let _this = this;
			let interval;

			if (Session.get('token')) {

				_this.getUserInfo().then(data => {
					_this.user = data;
				});



				let timeout = false;
				if (parseInt(_this.$root.config.set_user_online))
					timeout = parseInt(_this.$root.config.set_user_online);

				if (_this.user_is_auth) {
					if (timeout) {
						interval = setInterval(() => {
							if (!_this.isEmpty(_this.user.id)) {
								_this.setUserOnline();
							}
						}, timeout * 1000);
					}

					_this.setUserOnline();
				}
			} else {
				//this.$store.commit('setRedirectPath', this.$router.currentRoute.value.path);
				clearInterval(interval);

				//проверка на то, что хотя войти без маски
        const currentURL = new URL(window.location);
        var c = currentURL.searchParams.get("AZ");

				if (!c || c !== '1') {
          _this.$router.push('/logout');
        }
			}

			_this.emitter.on("updateEgrnCounter", (egrn_count) => {
				_this.user.services.egrn_count = egrn_count;
				_this.$store.commit('setUserInfo', _this.user);
			});

			_this.emitter.on("updateAdvertsBudget", () => {
				console.info('updateAdvertsBudget');
				_this.getAdvertsBudget().then(data => {
					_this.user.services.advert_budget = data;
					_this.$store.commit('setUserInfo', _this.user);
				});
			});
		},
		computed: {

			view_is_landscape() {
				return CommonService.isLandscape();
			},
			user_is_auth() {

				if (!this.isEmpty(this.$store.getters.userInfo.id))
					return true;

				return false;
			},
			is_user_name() {
				return !this.isEmpty(this.user.first_name) || !this.isEmpty(this.user.middle_name) || !this.isEmpty(this.user.last_name);
			},
			is_beta_tester() {
				let user_id = parseInt(this.$store.getters.userInfo.id);
				let beta_testers = this.$root.config.beta_testers_ids;
				return this.inArray(user_id, beta_testers);
			},
			user_show_details() {

				/*if (process.env.NODE_ENV == "development")
					return true;

				return false;*/
				return true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "src/assets/styles/app";
    .header {
        position: relative;
        z-index: $zindex-fixed;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
    }

    .user {
        &-info {
            max-width: 150px;
        }

        &-limit {
            border-radius: 2px;
        }
    }
</style>