<template>
  <div>
    <button type="button" class="burger crm__btn bg-secondary outline-0 border-0 p-0" data-bs-toggle="offcanvas" data-bs-target="#sidebarNav" aria-controls="sidebarNav">
      <img :src="require('@/assets/icons/burger.svg')" alt="Меню" width="24" height="24">
    </button>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarNav" aria-labelledby="sidebarNavLabel">
      <div class="offcanvas-header p-0 bg-secondary">
                <span class="fw-semi fs-2 text-white font-semi" style="padding-left: 25px;">
                    Меню
                </span>
        <button type="button" class="burger crm__btn bg-secondary border-0 p-0 ms-auto outline-0" data-bs-dismiss="offcanvas" aria-label="Close">
          <img :src="require('@/assets/icons/close-white-icon.svg')" alt="Меню" width="24" height="24">
        </button>
      </div>
      <div class="offcanvas-body d-flex flex-column bg-nav-list p-0 pt-3">
        <div class="row-fluid">
          <div class="col">
            <nav class="d-flex nav-links">
              <ul class="nav nav-pills d-flex flex-column w-100 p-0 m-0">
                <li v-for="(option, i) of this.mainMenu"
                    :key="'option_' + i"
                    class="nav-item d-inline-flex align-items-center px-0"
                    :data-current="(this.isCurrentRoute(option.link)) ? 'true' : 'false'">
                  <a href="javascript:{}"
                     class="nav-link d-flex align-items-center outline-0 px-4 py-md-3 font-normal fw-normal text-font-white fs-3"
                     :class="{
											 'active': this.isCurrentRoute(option.link),
											 'disabled': option.disabled
									   }"
                     data-bs-dismiss="offcanvas"
                     @click="this.toRoute(option.link)">
                    <img :src="require(`@/assets/icons/${option.iconName}.svg`)"
                         :alt="option.title"
                         width="33"
                         height="33"
                         class="me-10">
                    {{option.title}}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="row-fluid d-flex mt-auto pt-3">
          <div class="col">
            <div v-if="this.addMenu.length > 0" class="dropdown dropup d-flex mx-0 mt-auto py-2 pb-4 ps-4">
              <a href="javascript:{}"
                 class="btn dropdown-toggle d-flex align-items-center p-0 rounded-3"
                 data-bs-toggle="dropdown"
                 aria-expanded="false">
								<span class="d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
                      style="width:50px;height:50px;">
									<img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">
								</span>
                <span class="text-font-white fw-semi font-semi fs-3 px-2">Добавить</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="add-menu">
                <li v-for="(option, i) of this.addMenu"
                    :key="'add_' + i">
                  <a href="javascript:{}"
                     class="dropdown-item py-3"
                     data-bs-dismiss="offcanvas"
                     :class="{'disabled' : option.disabled}"
                     @click="this.toRoute(option.link/*, true*/)"
                     v-text="option.title" />
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <div class="d-flex mx-0 mt-auto py-2 pb-4 pe-3">
              <a href="javascript:{}"
                 class="btn dropdown-toggle d-flex align-items-center ms-auto p-0 rounded-3"
                 data-bs-toggle="dropdown"
                 data-bs-dismiss="offcanvas"
                 aria-expanded="false"
                 @click="this.toRoute('/logout')">
                <span class="text-font-white fw-semi font-semi fs-3 px-2">Выйти</span>
                <span class="d-flex align-items-center bg-danger rounded-circle justify-content-center me-1"
                      style="width:50px;height:50px;">
									<img :src="require('@/assets/icons/shutdown-icon.svg')" class="d-block" alt="Выйти" width="24" height="24">
								</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
  name: "Nav",

  data() {
    return {
      mainMenuList: [
        {
          id: 'charts',
          title: 'Кабинет',
          iconName:  'office',
          link: '/charts',
          disabled: true
        },
        {
          id: 'search',
          title: 'Поиск',
          iconName:  'search',
          link: '/search',
          disabled: false
        },
        {
          id: 'objects',
          title: 'Объекты',
          iconName:  'object',
          link: '/objects',
          disabled: false
        },
        {
          id: 'adverts',
          title: 'Продвижение',
          iconName:  'adv-icon',
          link: '/adverts',
          disabled: false
        },
        /*{
            id: 'reservations',
title: 'Брони',
            iconName:  'reserve',
            link: '/reservations',
disabled: true
        },*/
        {
          id: 'clients',
          title: 'Клиенты',
          iconName:  'clients',
          link: '/clients',
          disabled: false
        },
        {
          id: 'requisitions',
          title: 'Заявки',
          iconName:  'request',
          link: '/requisitions',
          disabled: false
        },
        {
          id: 'calendar',
          title: 'Календарь',
          iconName:  'calendar',
          link: '/calendar',
          disabled: false
        },
        {
          id: 'employees',
          title: 'Сотрудники',
          iconName:  'personal',
          link: '/employees',
          disabled: true
        },
        {
          id: 'partners',
          title: 'Партнеры',
          iconName:  'partners',
          link: '/partners',
          disabled: true
        },
        /*{
            id: 'docs',
            title: 'Документы',
            iconName:  'documents',
            link: '/docs',
disabled: true
        },*/
        {
          id: 'help',
          title: 'Обучение',
          iconName:  'learning',
          link: '/help',
          disabled: false
        }
      ],
      addMenuList: [
        {
          id: 'add-task',
          title: 'Новая задача',
          link: '#',
          disabled: true
        },
        {
          id: 'add-requisition',
          title: 'Новая заявка',
          link: '/add-requisition',
          disabled: false
        },
        {
          id: 'add-client',
          title: 'Новый клиент',
          link: '/add-client',
          disabled: false
        },
        {
          id: 'add-object',
          title: 'Новый объект',
          link: '/add-object',
          disabled: false
        },
        {
          id: 'add-employee',
          title: 'Новый сотрудник',
          link: '/add-employee',
          disabled: true
        },
      ]
    }
  },
  methods: {
    sortArray(data) {
      return CommonService.sortArray(data);
    },
    isEmpty(data) {
      return CommonService.isEmpty(data);
    },
    inArray(needle, haystack) {
      return CommonService.inArray(needle, haystack);
    },
    toRoute(url, refresh) {
      if (refresh) {
        window.location.href = url
      }
      else {
          this.$router.push(url);
      }
    },
    isCurrentRoute(url) {
      return (this.$router.currentRoute.value.path === url);
    }
  },
  computed: {
    user_can() {
      let user_id = parseInt(this.$store.getters.userInfo.id);
      let beta_testers = this.$root.config.beta_testers_ids;
      return {
        charts: this.inArray(user_id, beta_testers),
        //search: this.inArray(user_id, beta_testers),
        //objects: this.inArray(user_id, beta_testers),
        //adverts: this.inArray(user_id, beta_testers),
        //reservations: this.inArray(user_id, beta_testers),
        //clients: this.inArray(user_id, beta_testers),
        //requisitions: this.inArray(user_id, beta_testers),
        //calendar: this.inArray(user_id, beta_testers),
        //employees: this.inArray(user_id, beta_testers),
        //partners: this.inArray(user_id, beta_testers),
        //docs: this.inArray(user_id, beta_testers),
        //help: this.inArray(user_id, beta_testers),
      };
    },
    mainMenu() {
      return this.mainMenuList.filter((item) => {
        //if (!this.isEmpty(this.$store.getters.userInfo.user_can)) {
        if (!this.isEmpty(this.$store.getters.userInfo.permissions)) {
          if (item.id == 'search') {
            //if (this.$store.getters.userInfo.is_restricted_access) {
            if (this.$store.getters.userInfo.permissions.menu_search == 0) {
              // if (this.$route.name == 'search') {
              //   this.$router.push('/objects');
              // }
              return null;
            }
          } else if (item.id == 'objects') {
            //if (this.$store.getters.userInfo.is_restricted_access) {
            if (this.$store.getters.userInfo.permissions.menu_objects == 0) {
              // if (this.$route.name == 'objects') {
              //   this.$router.push('/clients');
              // }
              return null;
            }
          } else if (item.id == 'adverts') {
            //if (!this.$store.getters.userInfo.user_can.view_all_objects || this.$store.getters.userInfo.is_restricted_access) {
            if (this.$store.getters.userInfo.permissions.menu_ads == 0) {
              return null;
            }
          } else if (item.id == 'reservations') {
            if (!this.$store.getters.userInfo.is_newbuildings_access) {
              return null;
            }
          } else if (item.id == 'clients') {
            //if (this.$store.getters.userInfo.is_restricted_access) {
            if (this.$store.getters.userInfo.permissions.menu_clients == 0) {
              // if (this.$route.name == 'clients') {
              //   this.$router.push('/requisitions');
              // }
              return null;
            }
          } else if (item.id == 'requisitions') {
            //if (this.$store.getters.userInfo.is_restricted_access) {
            if (this.$store.getters.userInfo.permissions.menu_submissions == 0) {
              // if (this.$route.name == 'requisitions') {
              //   this.$router.push('/calendar');
              // }
              return null;
            }
          } else if (item.id == 'calendar') {
            if (this.$store.getters.userInfo.is_restricted_access) {
              return null;
            }
          } else if (item.id == 'employees') {
            if (this.$store.getters.userInfo.is_restricted_access) {
              return null;
            } else {
              if (!this.$store.getters.userInfo.is_agency || !this.$store.getters.userInfo.is_manager || !this.$store.getters.userInfo.is_users_admin) {
                return null;
              }
            }
          } else if (item.id == 'partners') {
            //if (this.$store.getters.userInfo.is_restricted_access) {
            if (this.$store.getters.userInfo.permissions.menu_partners == 0) {
              return null;
            } else {
              if (this.$store.getters.userInfo.id == 8407 || !this.$store.getters.userInfo.is_agency || !this.$store.getters.userInfo.is_manager || !this.$store.getters.userInfo.is_users_admin) {
                return null;
              }
            }
          } else if (item.id == 'help') {
            if (this.$store.getters.userInfo.is_restricted_access) {
              return null;
            } else {
              if (this.$store.getters.userInfo.id == 5530) {
                return null;
              }
            }
          }
        }
        else {
          if (item.id == 'adverts' && (!this.$store.getters.userInfo.is_agency  || !this.$store.getters.userInfo.is_users_admin)) {
            return null;
          }
          else if (item.id == 'employees' && (!this.$store.getters.userInfo.is_agency  || !this.$store.getters.userInfo.is_users_admin || !this.$store.getters.userInfo.is_manager)) {
            return null;
          }
          else if (item.id == 'partners' && (!this.$store.getters.userInfo.is_agency  || !this.$store.getters.userInfo.is_users_admin || !this.$store.getters.userInfo.is_manager)) {
            return null;
          }
        }

        if (item.disabled == true) {

          console.log(item.id, this.user_can[item.id]);

          if (!this.isEmpty(this.user_can[item.id]))
            item.disabled = !this.user_can[item.id];

        }

        //console.log(this.$route.name);
        return item;
      });
    },
    addMenu() {
      return this.addMenuList.filter((item) => {
        return item;
      });
    },
  }
};
</script>
