import * as bootstrap from "bootstrap";
import moment from 'moment';
import store from "../store";

class CommonService {

	delegatedListeners = {};

	setPageTitle(title) {

		if (process.env.NODE_ENV == "development")
			console.log('setPageTitle()', {title});

		return document.title = title + ' - JoyWork';
	}

	declOfNum(number, titles, append, is_price) {

		if (process.env.NODE_ENV == "development")
			console.log('declOfNum()', {number, titles, append});

		let cases = [2, 0, 1, 1, 1, 2];
		let count = Math.abs(number);

		if (is_price)
			number = this.formatPrice(number);

		return ((append === true) ? number + " " : '') + titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
	}

	formatPrice(value, currency, sign) {

		let post = '', prep = '';
		value = value.toString().replace(' ', '');
		let val = (parseFloat(value) / 1).toFixed(2).replace('.', ',');

		if (currency) {
			if (currency == "RUR") {
				post = (sign) ? " ₽" : " руб.";
			} else if (currency == "USD") {

				if (sign)
					prep = "$";
				else
					post = " долл. США";

			} else if (currency == "EUR") {

				if (sign)
					prep = "€";
				else
					post = " евро";
			}
		}

		value = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		value = value.replace(',00', "");
		return prep + value + post;
	}

	formatDateTime(datetime, locale, format) {

		/*if (process.env.NODE_ENV == "development")
			console.log('formatDateTime()', {datetime, locale});*/

		if (typeof locale !== "undefined")
			moment.locale(locale);

		let date = moment(datetime);
		if (date.isValid()) {
			if ((typeof format == "undefined") && moment().isSame(moment(datetime), 'day')) {
				return date.format('LLL');
			} else {
				if (typeof format !== "undefined")
					return date.format(format);
				else
					return date.format('LLL');
			}
		} else {
			return datetime;
		}
	}

	declOfNumber(number, words) {
		let value = Math.abs(number) % 100;
		let num = value % 10;
		let decl = '';

		if (value > 10 && value < 20)
			decl = words[2];

		if (num > 1 && num < 5)
			decl = words[1];

		if (num == 1)
			decl = words[0];

		decl = words[2];

		if (decl.indexOf('%s') !== -1)
			return decl.replace('%s', number);
		else if (decl.indexOf('%d') !== -1)
			return decl.replace('%d', number);

		return decl;

	}

	transliterate(word, space_replace) {

		const keys = {
			'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'ґ': 'g', 'д': 'd',
			'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i', 'й': 'i',
			'і': 'i', 'ї': 'yi', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
			'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
			'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch',
			'ъ': '', 'ь': '', 'ы': 'y', 'э': 'e', 'є': 'ye', 'ю': 'u', 'я': 'ya',
		};

		return word.split("").map((letter) => {
			const lower = letter.toLowerCase();
			const en = keys[lower] ?? letter;

			if (typeof space_replace !== "undefined")
				return lower === letter ? en.replace(/\s/g, space_replace) : en.replace(/\s/g, space_replace).toUpperCase();
			else
				return lower === letter ? en : en.toUpperCase();
		}).join("");
	}

	stringifyPrice(price) {
		if (price > 0 && price < 1)
			return this.declOfNumber(price, ['%d копейка', '%d копейки', '%d копеек']);
		else if (price >= 1 && price < 1000)
			return this.declOfNumber(price, ['%s рубль', '%s рубля', '%s рублей']);
		else
			return price;
	}

	cleanPhoneNumber(phone) {
		return ('' + phone).replace(/\D/g, '');
	}

	formatPhoneNumber(phone, link, intl) {

		if (!intl)
			intl = '+7';

		let cleaned = this.cleanPhoneNumber(phone);
		let match = cleaned.match(/^(7|8|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
		if (match) {
			let intlCode = (match[1] ? intl + ' ' : '');
			phone = [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
		}

		if (link)
			phone = 'tel:+' + this.cleanPhoneNumber(phone);

		return phone;
	}

	popoversInit(root, selector, options) {

		/*if (process.env.NODE_ENV == "development")
			console.log('popoversInit()', {root, selector, options});*/

		if (typeof root != "object")
			root = document || window.document;

		if (typeof selector == "undefined")
			selector = '[data-bs-toggle="popover"]';

		let allowed = bootstrap.Tooltip.Default.allowList;
		allowed.table = [];
		allowed.td = ['data-bs-option'];
		allowed.dl = [];
		allowed.dy = [];
		allowed.dd = [];

		const template = '<div class="popover d-block %class%" role="tooltip">' +
			'<div class="popover-arrow %arrow_class%"></div>' +
			'<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="popover" aria-label="Close"></button>' +
			'<h3 class="popover-header %header_class%"></h3>' +
			'<div class="popover-body %body_class%"></div>' +
		'</div>';

		let popoverClass = '';
		let popoverArrowClass = '';
		let popoverHeaderClass = '';
		let popoverBodyClass = '';
		let popoverOnlyOne = false;
		let defaults = {
			toggle: 'popover',
			allowList: allowed,
			offset: '20,20',
			fallbackPlacements: ['bottom'],
			container: 'body',
			template: template,
			sanitize: false
		};

		if (!this.isEmpty(options))
			options = (typeof options != "undefined") ? {...defaults, ...options} : defaults;
		else
			options = defaults;

		options = (typeof options != "undefined") ? {...defaults, ...options} : defaults;
		Array.from(root.querySelectorAll(selector)).reverse().forEach((trigger) => {

			for (const option in trigger.dataset) {
				let key = option.toString().replace(/^bs*/, '').toLowerCase();
				if (['animation', 'container', 'content', 'delay', 'html', 'placement',
							 'selector', 'template', 'title', 'trigger', 'fallbackplacements',
							 'boundary', 'customclass', 'sanitize', 'allowlist', 'sanitizefn',
							 'offset', 'popperconfig'].indexOf(key) !== -1) {

					if (trigger.dataset[option].length > 0)
						options[key] = trigger.dataset[option];

				}
			}

			if (trigger.dataset.bsClass)
				popoverClass = trigger.dataset.bsClass;

			if (trigger.dataset.bsArrowClass)
				popoverArrowClass = trigger.dataset.bsArrowClass;

			if (trigger.dataset.bsHeaderClass)
				popoverHeaderClass = trigger.dataset.bsHeaderClass;

			if (trigger.dataset.bsBodyClass)
				popoverBodyClass = trigger.dataset.bsBodyClass;

			if (trigger.dataset.bsOnlyOne)
				popoverOnlyOne = trigger.dataset.bsOnlyOne;

			options.template = template.replace('%class%', popoverClass)
				.replace('%arrow_class%', popoverArrowClass)
				.replace('%header_class%', popoverHeaderClass)
				.replace('%body_class%', popoverBodyClass);

			if (trigger.dataset.bsTarget) {
				let content = root.querySelector(trigger.dataset.bsTarget);
				if (content) {
					if (content.textContent.trim().length > 0) {
						options.content = content;
						options.html = true;
					}
				}
			}

			if (trigger.dataset.bsHtml) {
				if (trigger.dataset.bsHtml === "true")
					options.html = true;
				else
					options.html = false;
			}

			var popover = new bootstrap.Popover(trigger, options);
			trigger.addEventListener('shown.bs.popover', function (event) {
				var popover_id = event.target.getAttribute('aria-describedby');
				if (popover_id) {
					var popover_elm = document.getElementById(popover_id);
					if (popover_id) {

						if (popoverOnlyOne) {
							document.querySelectorAll('.popover.show').forEach((item) => {
								if (item.id !== popover_id) {
									let target = document.querySelector('[aria-describedby="'+ item.id +'"]');
									if (target) {
										target.hide();
									}
								}
							});
						}

						popover_elm.querySelector('[data-bs-dismiss="popover"]').onclick = () => {
							popover.hide();
						};
					}
				}
			})
		});
	}

	collapseInit(root, selector, options) {

		if (process.env.NODE_ENV == "development")
			console.log('collapseInit()', {root, selector, options});

		if (typeof root != 'object')
			root = document || window.document;

		if (typeof selector === "undefined")
			selector = '[data-bs-toggle="collapse"]';

		let defaults = {
			toggle: false,
		};

		options = (typeof options != "undefined") ? {...defaults, ...options} : defaults;
		Array.from(root.querySelectorAll(selector)).forEach((collapse) => {

			for (const option in collapse.dataset) {
				let key = option.toString().replace(/^bs*/, '').toLowerCase();
				if (['parent', 'controls', 'aria-expanded', 'target'].indexOf(key) !== -1) {

					if (collapse.dataset[option].length > 0)
						options[key] = collapse.dataset[option];

				}
			}

			if (collapse.dataset.bsTarget) {
				let target = root.querySelector(collapse.dataset.bsTarget);
				if (typeof target != "undefined") {
					target.classList.add('collapse');
				}
			}

			if (collapse.dataset.ariaExpanded) {
				let target = root.querySelector(collapse.dataset.bsTarget);
				if (typeof target != "undefined") {
					target.classList.add('collapse');
				}
			}

			new bootstrap.Collapse(collapse, options);

		});

	}

	getModal(modal_id, options) {

		if (typeof modal_id  != "undefined") {

			let target = document.getElementById(modal_id);
			if (typeof options != "undefined")
				return bootstrap.Modal.getInstance(target);
			else
				return bootstrap.Modal.getOrCreateInstance(target, options);

		}

		return false;
	}

	stripTags(string) {
		let pattern = new RegExp('<[^>]+>', 'gi'); // tags
		let pattern2 = new RegExp('&[^w+]+;', 'gi'); // html entities
		string = string.replace(pattern, '');
		string = string.replace(pattern2, '');
		return string;
	}

	arrayCompare(a1, a2) {
		if (a1.length != a2.length) return false;
		var length = a2.length;
		for (var i = 0; i < length; i++) {
			if (a1[i] !== a2[i]) return false;
		}
		return true;
	}

	arrayJoin(arr /*, separator */) {
		let separator = arguments.length > 1 ? arguments[1] : ", ";
		return arr.filter(function(n) {
			return n
		}).join(separator);
	}

	inArray(needle, haystack) {

		if (typeof needle === "undefined" || typeof haystack === "undefined")
			return false;

		if (this.isEmpty(needle) || this.isEmpty(haystack))
			return false;

		if (typeof haystack == "object") {
			var length = haystack.length ?? 0;
			for(var i = 0; i < length; i++) {
				if(typeof haystack[i] == 'object') {
					if(this.arrayCompare(haystack[i], needle)) return true;
				} else {
					if(haystack[i] == needle) return true;
				}
			}
		}

		return false;
	}

	sortArray(data, key) {
		if (typeof data !== "undefined") {
			if (typeof key !== "undefined")
				return [...data].sort((a, b) => a[key].toString().localeCompare(b[key]));
			else
				return [...data].sort((a, b) => a.name.toString().localeCompare(b.name));
		}

		return data;
	}

	resetProxy(object) {
		let string = JSON.stringify(object);
		return  JSON.parse(string);
	}

	splitJoin(data, delimiter) {

		if (typeof delimiter == "undefined")
			delimiter = ',';

		if (typeof data == "string")
			return data.split(delimiter);
		else if (typeof data == "object")
			return data.join(delimiter);
		else
			return data;
	}

	clearAllModals() {

		let modals = document.querySelectorAll('.modal.fade.show');
		if (modals)
			modals.forEach(el => el.classList = ['modal', 'fade']);

		let backdrops = document.querySelectorAll('.modal-backdrop.fade.show');
		if (backdrops)
			backdrops.forEach(el => el.remove());

	}

	clearAllPopovers() {

		let popovers = document.querySelectorAll('.popover.fade.show');
		if (popovers)
			popovers.forEach(el => el.classList = ['popover', 'fade']);
	}

	isEmpty(data) {
		if (typeof (data) === 'object') {
			if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
				return true;
			} else if (!data) {
				return true;
			}
			return false;
		} else if (typeof (data) === 'string') {

			if (!data.trim() || data == "undefined" || data == "false" || data == "NULL" || data == "0" || data == "0.00")
				return true;

			return false;
		} else if (typeof (data) === 'undefined') {
			return true;
		} else {
			return (!data);
		}
	}

	mergeRecursive(object1, object2) {

		for (let prop in object2) {

			try {
				if (object2[prop].constructor == Object) {
					object1[prop] = this.mergeRecursive(object1[prop], object2[prop]);
				} else {
					object1[prop] = object2[prop];
				}
			} catch(e) {
				object1[prop] = object2[prop];
			}
		}

		return object1;
	}

	newArray(length, random) {

		if (typeof length == "undefined")
			length = 0;

		if (typeof random == "undefined")
			random = false;

		if (random)
			return Array.apply(null, {length: length}).map(Function.call, Math.random);
		else
			return Array.from({length: length}, (e, i) => i + 1);
	}

	buildIconImage(path, class_name, title, size) {

		if (typeof path == "undefined")
			return '';

		if (typeof class_name == "undefined")
			class_name = 'icon';
		else
			class_name = 'icon ' + class_name;

		if (typeof title == "undefined")
			title = '';

		if (typeof size == "undefined")
			size = [14, 14];

		return '<img src="' + path + '" class="'+ class_name +'" alt="'+ title +'" width="'+ size[0] +'" height="'+ size[1] +'" />';
	}

	proxyToObject(data) {
		return JSON.parse(JSON.stringify(data));
	}

	removeEmpty(data) {
		if (typeof (data) === 'object') {
			//return Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));
			return Object.fromEntries(Object.entries(data).filter(([_, v]) => !this.isEmpty(v)));
		}
	}

	resetArray(data) {
		return data.filter(function(val) {
			return val;
		});
	}

	arrayDiff(data, data2) {
		return data.filter((a) => data2.indexOf(a) == -1);
	}

	uniqueArray(data, field) {
		if (typeof field !== "undefined")
			return [...new Set(data.map(item => item[field]))];
		else
			return [...new Set(data)];
	}

	findPosition(element) {
		var top = 0;
		if (element.offsetParent) {
			do {
				top += element.offsetTop;
			} while (element == element.offsetParent);
			return top;
		}

		return top;
	}

	getTimestamp(interval, unix_format) {

		let timestamp;
		if (interval) {

			const date = new Date();
			const value = parseInt(interval.match(/\d+/)[0]);

			if (interval.indexOf('+') !== -1) {

				if (interval.toLowerCase().indexOf('year') !== -1)
					date.setFullYear(date.getFullYear() + value);
				else if (interval.toLowerCase().indexOf('month') !== -1)
					date.setMonth(date.getMonth() + value);
				else if (interval.toLowerCase().indexOf('day') !== -1)
					date.setDate(date.getDate() + value);
				else if (interval.toLowerCase().indexOf('hour') !== -1)
					date.setHours(date.getHours() + value);
				else if (interval.toLowerCase().indexOf('minute') !== -1)
					date.setMinutes(date.getMinutes() + value);

			} else if (interval.indexOf('-') !== -1) {

				if (interval.toLowerCase().indexOf('year') !== -1)
					date.setFullYear(date.getFullYear() - value);
				else if (interval.toLowerCase().indexOf('month') !== -1)
					date.setMonth(date.getMonth() - value);
				else if (interval.toLowerCase().indexOf('day') !== -1)
					date.setDate(date.getDate() - value);
				else if (interval.toLowerCase().indexOf('hour') !== -1)
					date.setHours(date.getHours() - value);
				else if (interval.toLowerCase().indexOf('minute') !== -1)
					date.setMinutes(date.getMinutes() - value);

			}

			timestamp = date.getTime();
		} else {
			timestamp = Date.now();
		}

		if (unix_format)
			return timestamp / 1000 | 0;
		else
			return timestamp;
	}

	scrollIntoView(element, offset) {
		let top = this.findPosition(element);
		let _offset = parseInt(offset ?? 0) ?? (window.screen.height/2);
		let options = {
			top: (top - _offset),
			behavior: 'smooth'
		};
		window.scroll(options);
	}

	strpos(haystack, needle, offset) {
		const i = haystack.indexOf(needle, offset); // returns -1
		return i >= 0 ? i : false;
	}

	binarySearch(haystack, needle) {

		if (typeof (needle) !== "object" || typeof (haystack) !== "number")
			return false;

		let left = -1;
		let right = needle.length;
		while(right - left > 1) {

			const middle = Math.floor((left + right) / 2);

			if (haystack === needle[middle]) {
				return middle;
			}

			if (haystack < needle[middle]) {
				right = middle;
			} else {
				left = middle;
			}

			return false;
		}
	}

	is_beta_tester() {
		let config = '';
		let user_id = parseInt(store.getters.userInfo.id);
		let beta_testers = store.getters.config.beta_testers_ids;
		return this.inArray(user_id, beta_testers);
	}

	dateAdd(date, units, interval, timestamp, unix_timestamp) {

		if(!(date instanceof Date))
			return undefined;

		let new_date = new Date(date); //don't change original date
		let checkRollover = function() {
			if(new_date.getDate() != date.getDate()) new_date.setDate(0);
		};

		switch(String(interval).toLowerCase()) {

			case 'year':
				new_date.setFullYear(new_date.getFullYear() + units); checkRollover();
				break;

			case 'quarter':
				new_date.setMonth(new_date.getMonth() + 3*units); checkRollover();
				break;

			case 'month':
				new_date.setMonth(new_date.getMonth() + units); checkRollover();
				break;

			case 'week':
				new_date.setDate(new_date.getDate() + 7*units);
				break;

			case 'day':
				new_date.setDate(new_date.getDate() + units);
				break;

			case 'hour':
				new_date.setTime(new_date.getTime() + units*3600000);
				break;

			case 'minute':
				new_date.setTime(new_date.getTime() + units*60000);
				break;

			case 'second':
				new_date.setTime(new_date.getTime() + units*1000);
				break;

			default:
				new_date = undefined;
				break;

		}

		if (timestamp) {
			if (unix_timestamp) {
				return Math.floor(new_date.getTime() / 1000);
			} else {
				return new_date.getTime();
			}
		} else {
			return new_date;
		}
	}

	log(type, name, message) {

		let is_data = true;

		if (typeof message == 'string')
			is_data = false;

		store.commit('setLog', {datetime: moment(new Date()).format('DD.MM.YY / HH:mm:ss'), type: type, name: name, message: message, is_data: is_data});

		if (process.env.NODE_ENV == "development" || this.is_beta_tester()) {
			switch (type) {
				case 'info':
					console.info(name.toString(), message);
					break;

				case 'debug':
					console.debug(name.toString(), message);
					break;

				case 'error':
					console.error(name.toString(), message);
					break;

				default:
					console.log(name.toString(), message);
					break;
			}
		}
	}

	debounce(callback, delay) {
		window.clearTimeout(window.debounceTimer);
		window.debounceTimer = window.setTimeout(callback, delay);
	}

	throttle(callback, delay) {
		if (window.throttleAwait)
			return;

		window.throttleAwait = true;
		setTimeout(() => {
			callback();
			window.throttleAwait = false;
		}, delay);
	}

	delegate(type, selector, callback) {
		if (!this.delegatedListeners[type]) {
			const listeners = this.delegatedListeners[type] = [];
			document.addEventListener(type, function(event) {
				for (let element = event.target; element && element.parentNode; element = element.parentNode) {
					// unfortunately, event.currentTarget cannot be overwritten with element
					for (const {selector, callback} of listeners) {
						if (element.matches(selector)) {
							callback.call(element, event);
						}
					}
					if (event.cancelBubble) { // a getter for the propagation stopped flag :-)
						break;
					}
				}
			});
		}
		this.delegatedListeners[type].push({selector, callback});
	}

	validateEmail(email) {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	}

	getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2)
			return parts.pop().split(';').shift();
	}

	roundTo(value, num) {
		let new_value = 0;
		let mod = value % num;
		if (mod <= (num / 2))
			new_value = (value - mod);
		else
			new_value = (value + num - mod);

		return new_value;
	}

	copyToClipboard = async function (text, parent) {
		console.info('copyToClipboard()', text);
		return new Promise((resolve, reject) => {
			if (
				typeof navigator !== "undefined" &&
				typeof navigator.clipboard !== "undefined" &&
				navigator.permissions !== "undefined"
			) {
				//const { ClipboardItem } = window;
				//const type = "text/plain";
				//const blob = new Blob([text], { type });
				//const data = [new ClipboardItem({ [type]: blob })];
				navigator.permissions.query({name: "clipboard-write"}).then((permission) => {
					if (permission.state === "granted" || permission.state === "prompt") {
						navigator.clipboard.writeText(text).then(() => {
							alert('Успешно скопировано!');
							console.log('Copy to clipboard success: ' + text);
							return true;
						}).catch((error) => {
							alert('Ошибка 521. Не удалось скопировать! Вы можете скопировать текст самостоятельно: \r\n' + text);
							console.error('Copy to clipboard failed: ', error);
							return false;
						});
					} else {
						reject(() => {
							alert('Ошибка 522. Разрешение на копирование не было предоставлено! Вы можете скопировать текст самостоятельно: \r\n' + text);
							return new Error("Permission not granted!");
						});
					}
				});
			} else if (
				document.queryCommandSupported &&
				document.queryCommandSupported("copy")
			) {
				var textarea = document.createElement("textarea");
				textarea.textContent = text;
				textarea.className = "d-none";
				textarea.style.position = "fixed";
				textarea.style.width = '2em';
				textarea.style.height = '2em';
				textarea.style.padding = 0;
				textarea.style.border = 'none';
				textarea.style.outline = 'none';
				textarea.style.boxShadow = 'none';
				textarea.style.background = 'transparent';

				if (typeof parent != "undefined")
					parent.appendChild(textarea);
				else
					document.body.appendChild(textarea);

				textarea.focus();
				textarea.select();
				try {
					document.execCommand("copy");
					document.body.removeChild(textarea);
					alert('Успешно скопировано!');
					resolve();
				} catch (e) {
					document.body.removeChild(textarea);
					alert('Ошибка 523. Не удалось скопировать! Вы можете скопировать текст самостоятельно: \r\n' + text);
					reject(e);
				}
			} else {
				reject(() => {
					alert('Ошибка 524. Ни один из методов не поддерживается! Вы можете скопировать текст самостоятельно: \r\n' + text);
					return new Error("None of copying methods are supported by this browser!");
				});
			}
		});
	}

	validate = {
		isEmail(str) {
			let pattern =/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
			return pattern.test(str);
		},
		isNotEmpty(str) {
			let pattern =/\S+/;
			return pattern.test(str);
		},
		isNumber(str) {
			let pattern = /^\d+\.?\d*$/;
			return pattern.test(str);
		},
		isSame(str1, str2) {
			return str1 === str2;
		},
	}

	formatBytes(bytes, decimals = 2) {
		/*if (bytes === 0)
			return '0 Bytes';*/

		if (bytes === 0)
			return '0 Байт';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		//const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
		const sizes = ['Байт', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Эб', 'Зб', 'Йб'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	// helps you detect mobile browsers (to show a relevant message as the process of installing your PWA changes from browser to browser)
	isAndroid() {
		return navigator.userAgent.match(/Android/i);
	}
	isBlackBerry() {
		return navigator.userAgent.match(/BlackBerry/i);
	}
	isiOS() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	}
	isOpera() {
		return navigator.userAgent.match(/Opera Mini/i);
	}
	isSamsung() {
		return navigator.userAgent.match(
			/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
		);
	}
	isWindows() {
		return (
			navigator.userAgent.match(/IEMobile/i) ||
			navigator.userAgent.match(/WPDesktop/i)
		);
	}
	isMobile() {
		return (
			this.isAndroid() ||
			this.isBlackBerry() ||
			this.isiOS() ||
			this.isOpera() ||
			this.isSamsung() ||
			this.isWindows()
		);
	}

	// use this to check if the user is already using your PWA - no need to prompt if in standalone
	isStandalone() {
		const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
		if (document.referrer.startsWith("android-app://")) {
			return true; // Trusted web app
		} else if ("standalone" in window.navigator || isStandalone) {
			return true;
		}
		return false;
	}

	isPortrait() {
		return window.innerHeight > window.innerWidth;
	}

	isLandscape() {
		return window.innerHeight < window.innerWidth;
	}
}

export default new CommonService();
