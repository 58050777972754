import { createWebHistory, createRouter } from "vue-router";
import CommonService from "@/services/CommonService";

const routes =  [
  {
    path: "/login",
    alias: "/login",
    name: "login",
    component: () => import("./pages/Login"),
  }, {
    path: "/register",
    alias: "/register",
    name: "register",
    component: () => import("./pages/Register"),
  }, {
    path: "/restore",
    alias: "/restore",
    name: "restore",
    component: () => import("./pages/Restore"),
  }, {
    path: "/logout",
    redirect: { name: 'login' },
  },

  {
    path: "/charts",
    name: "charts",
    component: () => import("./pages/Charts")
  }, {
    path: "/",
    alias: "/search",
    name: "search",
    component: () => import("./pages/Search")
  }, {
    path: "/objects",
    name: "objects",
    component: () => import("./pages/Objects")
  }, {
    path: "/add-object",
    name: "add-object",
    component: () => import("./pages/AddObject"),
    props: (route) => ({ returnPath: route.query.return, clientId: route.query.client_id, restoreForm: route.query.restore_form }),
  }, {
    path: "/edit-object",
    name: "edit-object",
    component: () => import("./pages/AddObject"),
    props: (route) => ({ returnPath: route.query.return, objectId: route.query.id, clientId: route.query.client_id, restoreForm: route.query.restore_form }),
  }, {
    path: "/adverts",
    name: "adverts",
    component: () => import("./pages/Adverts"),
  },  {
    path: "/reservations",
    name: "reservations",
    component: () => import("./pages/Reservations"),
    props: (route) => ({ funnelId: route.query.funnel_id }),
  }, {
    path: "/clients",
    name: "clients",
    component: () => import("./pages/Clients"),
    props: (route) => ({ funnelId: route.query.funnel_id }),
  }, {
    path: "/add-client",
    name: "add-client",
    component: () => import("./pages/AddClient"),
    props: (route) => ({ returnPath: route.query.return, funnelId: route.query.funnel_id, objectId: route.query.object_id, restoreForm: route.query.restore_form }),
  }, {
    path: "/edit-client",
    name: "edit-client",
    component: () => import("./pages/AddClient"),
    props: (route) => ({ returnPath: route.query.return, clientId: route.query.id, restoreForm: route.query.restore_form }),
  }, {
    path: "/requisitions",
    name: "requisitions",
    component: () => import("./pages/Requisitions"),
    props: (route) => ({ funnelId: route.query.funnel_id }),
  }, {
    path: "/add-requisition",
    name: "add-requisition",
    component: () => import("./pages/AddRequisition"),
    props: (route) => ({ returnPath: route.query.return, funnelId: route.query.funnel_id, typeId: route.query.type_id, objectId: route.query.object_id, clientId: route.query.client_id, restoreForm: route.query.restore_form }),
  }, {
    path: "/edit-requisition",
    name: "edit-requisition",
    component: () => import("./pages/AddRequisition"),
    props: (route) => ({ returnPath: route.query.return, requisitionId: route.query.id, clientId: route.query.client_id, restoreForm: route.query.restore_form }),
  }, {
    path: "/calendar",
    name: "calendar",
    component: () => import("./pages/Calendar")
  }, {
    path: "/employees",
    name: "employees",
    component: () => import("./pages/Employees")
  }, {
    path: "/partners",
    name: "partners",
    component: () => import("./pages/Partners")
  }, {
    path: "/docs",
    name: "docs",
    component: () => import("./pages/Docs")
  }, {
    path: "/help",
    name: "help",
    component: () => import("./pages/Help")
  },
  {
    path: "/status",
    name: "status",
    component: () => import("./pages/Status")
  },

  {
    path: "/icons",
    name: "icons",
    component: () => import("./pages/ICONS")
  },

  {
    path: "/error-402",
    name: "restricted",
    component: () => import("./pages/RestrictedAccess")
  },
  {
    path: "/error-403",
    name: "denied",
    component: () => import("./pages/DeniedAccess")
  }, {
    path: "/error-404",
    name: "not-found",
    component: () => import("./pages/NotFound")
  }, {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'not-found' },
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior (to) {
    /*if (to.hash) {
      console.info(to);
      document.getElementById(to.hash).scrollIntoView();
      CommonService.debounce(document.getElementById(to.hash).scrollIntoView(), 5000);
      return {
        selector: to.hash
      }
    }*/
    document.getElementById('app').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  },
});

export default router;